<template>
  <PageHeader :title="title" />
  
  <tablecustom 
    @search="searchB" 
    :dataParams="dataParams"
    :objParams="objParams"
    :columns="columns" 
    :rows="rows" 
    :pages="objPages"
    :showpag="true" 
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
    @open="open"
  />

  <!-- карточка задачи -->
  <viewtask 
    v-if="showModal == true" 
    @close="showModal = false" 
    :shortapplic="obj"
  ></viewtask>

</template>

<script>
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import viewtask from '@/views/tasks/view/viewtask.vue'
import { mutateTypeTask, mutateStatusTask } from '@/usabilityScripts/globalMutate'
import { Archive } from '@/API.js';
import { storeS } from "@/store";

let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks,
    viewtask
  },
  data() {
    return {
      title: "archiveTasks",
      showModal: false,
      obj: '',
      objPages: {},
      dataParams: {
          status: true,
          page: "tasks"
      },
      objParams:{
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          taskName: '',
          priority: '',
          workerId: '',
          pr1: '',
          pr2: ''
      },
      columns: [
        {
          name: "ID",
          text: "taskId",
          align: "left",
          status: true,
        },
        {
          name: this.$t('Task'),
          text: "taskName",
          align: "left",
          status: true,
          mutate: (item) => this.mutateType(item)
        },
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          status: true,
          mutate: (item, obj) => this.mutateStatus(item, obj).name,
          mutateClass: (item, obj) => this.mutateStatus(item, obj).color
        },
        {
          name: this.$t("Manager"),
          text: "closedWorkerName",
          align: "right",
          status: true,
          mutate: (item, obj) => this.mutateWorker(item, obj)
        }
      ],
      rows: [],
    };
  },
  created() {
    // this.objParams.pr1 = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
    // this.objParams.pr2 = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()
    this.getdata();
  },
  methods: {
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiArchive.getAllArchiveTasks(this.objParams).then(result => {
        if(result.status == 'done'){
          this.objPages = result.data;
          this.rows = result.data.items;
        }
      })
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    open: function(e){
      apiArchive.getArchiveTask(e.taskId).then(result => {
        if(result.status == 'done'){
          this.obj = result.data;
          this.showModal = true;
        }
      })
    },
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    changeFilter(name, value){
      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      } else {
        this.objParams = {
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          taskName: '',
          priority: '',
          workerId: '',
          pr1: '',
          pr2: ''
        },
        this.getdata()
      }
    },
    mutateType(e){
      return mutateTypeTask(e)
    },
    mutateStatus(e, obj){
      return mutateStatusTask(e, obj)
    },
    mutateWorker(e, obj){
      return obj.closed.workerName != '' ? obj.closed.workerName : '-' 
    }
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    check() {
      return storeS.checks
    },
  },
};
</script>

<style scoped>
  .tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
  }
  .howto_block {
  margin-left:10px;
  }
  .howto_block i {
  font-size: 23px;
  opacity: 0.5;
  }
  .howto_block i:hover{
  opacity: 1;
  }

  .howto_block {
  position: relative;
  display: inline-block;
  }

  .howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
  }

  .howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
  }

  .howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  }
</style>
